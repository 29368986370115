import React, { useEffect, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";

const FormPrint = ({selectedFormID,selectedTemplateID}) => {
  const [inputStates, setInputStates] = React.useState({});
  const [inputFields, setInputFields] = React.useState([]);
  const [filteredInputFields, setfilteredInputFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { reportID, templateID} = useParams();
  const [newReportID, setNewReportID] = React.useState(reportID || selectedFormID);
  const [newTemplateID, setNewTemplateID] = React.useState(templateID || selectedTemplateID);
  const [printing, setPrinting] = React.useState(false);
  const [formInfo, setFormInfo] = React.useState({});
  const [selectedOption, setSelectedOption] = React.useState({});
  const [Factory, setFactory] = React.useState([]);
  const [Shop, setShop] = React.useState([]);
  const [Product, setProduct] = React.useState([]);
  const currentRoute = window.location.href;
  const routeParts = currentRoute.split('/');
  const lastSegment = routeParts[routeParts.length - 1];
  const elementRef = useRef(null);

  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];

      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      // delete newDataObject.reportDate;
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplate = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `templates/${id}`)
        .then((res) => {
          let data = res.data[0];
          setFormInfo(data);
          axios.get(data?.url).then((respose) => {
            setInputFields(respose.data);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const fetchCompetents = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `competents/${id}`)
        .then((res) => {
          let data = res.data?.[0];
          setSelectedOption(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist Competents: ", e);
    }
  };

  const fetchFactory = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `clients`)
        .then((res) => {
          let data = res.data;
          setFactory(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Factory : ", e);
    }
  };

  const fetchShop = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `shops`)
        .then((res) => {
          let data = res.data;
          setShop(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Shop : ", e);
    }
  };

  const fetchProduct = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `products`)
        .then((res) => {
          let data = res.data;
          setProduct(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Product : ", e);
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const handlePrint = () => {
    setPrinting(true);
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      if(lastSegment !== "print"){
        window.location.reload();
      }

    }
  };

  const renderStaticSection = () => {
    return (
      <>
        <TableRow>
          <TableCell
            align="center"
            style={{
              backgroundColor: formInfo?.name?.includes("13")
                ? "#4473c5"
                : "#ed7d31",
              border: "none",
            }}
            colSpan={3}
          >
            <h4
              style={{
                textTransform: "uppercase",
                fontSize: "0.6rem",
                marginTop: "5px",
              }}
            >
              <strong>{formInfo?.name}</strong>
            </h4>
            <>
              <h6 style={{ fontSize: "0.6rem" }}>
                <b>{formInfo?.title && JSON.parse(formInfo?.title)?.rule}</b>
              </h6>
              <h6 style={{ fontSize: "0.6rem", marginBottom: "5px" }}>
                <b>{formInfo?.title && JSON.parse(formInfo?.title)?.title}</b>
              </h6>
            </>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" sx={{ border: "none" }} colSpan={3}>
            {/* {reportDetails && reportDetails.name ? ( */}
            <div>
              <h5 style={{ fontSize: "0.6rem" }}>
                <b>Report No:{inputStates?.reportNumber || ""} </b>
              </h5>
            </div>
            {/* ) : null} */}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const renderDynamicTable = () => {
    return (
      <>
        {filteredInputFields?.map((field, index) => (
          <TableRow key={index} style={{ height: "10px" }}>
            <TableCell style={{ backgroundColor: "#fff2cd", width: "50%" }}>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "0.6rem",
                  textAlign: "left",
                  paddingRight: "5px",
                }}
              >
                <b>{field?.label}</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#d9d9d9",
                width: "1%",
                textAlign: "center",
              }}
            >
              :
            </TableCell>
            <TableCell style={{ backgroundColor: "#fff2cd", width: "49%" }}>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "5px",
                  fontSize: "0.6rem",
                }}
              >
                <b>
                  {field?.stateKey === "factoryId"
                    ? Factory?.filter(
                        (ele) => ele.id === inputStates?.[field?.stateKey]
                      )?.[0]?.name
                    : field?.stateKey === "shopId"
                    ? Shop?.filter(
                        (ele) => ele.id === inputStates?.[field?.stateKey]
                      )?.[0]?.name
                    : field?.stateKey === "equipmentId"
                    ? Product?.filter(
                        (ele) => ele.id === inputStates?.[field?.stateKey]
                      )?.[0]?.name
                    : field?.type === "DatePicker"
                    ? new Date(
                        inputStates?.[field?.stateKey]
                      ).toLocaleDateString("en-GB")
                    : inputStates?.[field?.stateKey]}
                </b>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const renderSignatureSection = () => {
    function getFormattedDate() {
      const today = inputStates?.reportDate
        ? new Date(inputStates?.reportDate)
        : new Date();
      const formattedDate = today.toLocaleDateString("en-GB");
      return formattedDate;
    }

    function getNextYearDate() {
      const today = inputStates?.reportDate
        ? new Date(inputStates?.reportDate)
        : new Date();
      const nextYear = new Date(today);
      nextYear.setFullYear(today.getFullYear() + 1);
      const formattedNextYearDate = inputStates["certificateValidity "] 
      ? moment(inputStates["certificateValidity "] ).format("DD/MM/YYYY")
      : nextYear.toLocaleDateString("en-GB");
    
      return formattedNextYearDate;
    }

    return (
      <div style={{ textAlign: "left", fontSize: "0.6rem" }}>
        <br />
        <h6>
          I/We certify that on <b>{getFormattedDate()}</b> I/ We thoroughly
          examined the hoist, lift, lifting machine, ropes and lifting tackles
          and details of examinations / test carried out is enclosed herewith
          that the above is a correct report of the result.
        </h6>
        <br />
        <b>Next Due Date:- {getNextYearDate()}</b>
        <table style={{ pageBreakInside: "avoid", width: "100%" }}>
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#fff2cd",
                  width: "37%",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Signature of Competent Person</b>
                </div>
              </td>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#d9d9d9",
                  width: "1.3%",
                  textAlign: "center",
                }}
              >
                :
              </td>
              <td style={{ width: "37%" }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                  }}
                ></div>
              </td>
              <td style={{ width: "25%" }} rowSpan={5}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                  }}
                ></div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#fff2cd",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Name of Competent Person </b>
                </div>
              </td>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                }}
              >
                :
              </td>
              <td>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                  }}
                >
                  {/* <Autocomplete
                options={competents}
                size="small"
                getOptionLabel={(option) => option.personName}
                onChange={(event, newValue) => {
                  setSelectedOption(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.personName}
                    <small>{option.email}</small>
                  </li>
                )}
              /> */}
                  {selectedOption?.personName}
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#fff2cd",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Qualification</b>
                </div>
              </td>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                }}
              >
                :
              </td>
              <td>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                  }}
                >
                  {selectedOption?.qualification}
                </div>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#fff2cd",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Address</b>
                </div>
              </td>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                }}
              >
                :
              </td>
              <td>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                  }}
                >
                  {selectedOption?.address}
                </div>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#fff2cd",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Date</b>
                </div>
              </td>
              <td
                style={{
                  borderRight: "1px solid black",
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                }}
              >
                :
              </td>
              <td>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: "0.6rem",
                  }}
                >
                  {selectedOption ? getFormattedDate() : ""}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  function filterInputFields(inputFields, stateKeyArray) {
    return inputFields.filter(field => !stateKeyArray.includes(field.stateKey));
}

useEffect(()=>{
  const stateKeyArray = ["competentPerson", "reportNumber","reportDate","factoryId","shopId","equipmentId","totalTest","capacity","renewalInformation ","inspectionDate ","certificateValidity ","distinctiveNumber","inspectionDate","utrasonicTestDate","hydraulicTestDate","internalTestDate","internalExaminationDueDate","certificateValidityDate"];
  const filteredInputFields = filterInputFields(inputFields, stateKeyArray);
  setfilteredInputFields(filteredInputFields)

},[inputFields])


useEffect(()=>{

if (lastSegment === 'print') {
  setTimeout(() => {
    handlePrint();
  }, 1000);
} 
},[])

  useEffect(() => {
    if (newTemplateID > 0) {
      fetchTemplate(newTemplateID);
    }
    if (newReportID > 0) {
      fetchformReport(newReportID);
    }
  }, [newReportID, newTemplateID]);

  useEffect(() => {
    if (inputStates?.competentPerson)
      fetchCompetents(inputStates?.competentPerson);
  }, [inputStates?.competentPerson]);

  useEffect(() => {
    fetchProduct();
    fetchShop();
    fetchFactory();
  }, []);

  return (
    <div
      id="formToPrint"
      ref={elementRef}
      style={{ width: "100%", fontSize: "16px", margin: "auto" }}
    >
      <div
        style={{
          width: "96%",
          fontSize: "16px",
          border: "1px solid black",
          margin: "auto",
          padding: "7px",
        }}
      >
        <Table>
          <TableBody>
            {renderStaticSection()}

            {renderDynamicTable()}
          </TableBody>
        </Table>
        {renderSignatureSection()}
      </div>
      {reportID &&
            <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ marginRight: "20px" }}
              onClick={handlePrint}
            >
              Report Print
            </Button>
          </Toolbar>
      }

    </div>
  );
};

export default FormPrint;
