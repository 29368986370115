import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import FormPrint from "./formPrint";
import { Button, Toolbar } from "@mui/material";

const MultiplePrintForm = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [FormData, setFormData] = useState([]);
  const elementRef = useRef(null);

  const fetchFormData = () => {
    try {
      let SQLQuery = {
        query: `SELECT id, templateId FROM reportValues WHERE id IN (${ids.join(
          ","
        )}) ORDER BY id DESC`,
      };

      axiosWithToken.post(BASE_URL + `dynamic`, SQLQuery).then((res) => {
        let data = res.data;
        setFormData(data);
      });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const handlePrint = () => {

    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };

  useEffect(() => {
    if (ids?.length > 0) fetchFormData();
  }, [ids]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);
  return (
    <>
      <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ marginRight: "20px" }}
              onClick={handlePrint}
            >
              Report Print
            </Button>
          </Toolbar>
    <div
     id="formToPrint"
     ref={elementRef}
    >
      {FormData?.map((ele) => {
        return (
          <div style={{ pageBreakAfter: 'always' }} >
            <FormPrint 
            selectedFormID ={ele?.id}
            selectedTemplateID={ele?.templateId}
            />
            
          </div>
        );
      })}
    </div>
  
    </>
  );
};

export default MultiplePrintForm;
